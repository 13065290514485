// Caroussel3

function carrousel3(itemVisible) {
    var carouselWrap = $('.carrousel3'),
        carousel = $('.listItems', carouselWrap);

    if (carouselWrap.length > 0) {
        var nbItem = $('.item', carousel).length,
            nbItemConfig;

        if (itemVisible) {
            nbItemConfig = itemVisible;
        } else {
            nbItemConfig = '6';
        }

        if (nbItem > nbItemConfig) {
            if ($('.prevNextCaroussel3').length == 0) {
                carousel.parent().append('<ul class="prevNextCaroussel3">' +
                    '<li class="prevCaroussel3"><button type="button" class="button-reset2"><img src="' + pathImg + 'carrousel3/carrousel3-prev.png" alt="' + l10n['prev'] + '"></button></li>' +
                    '<li class="nextCaroussel3"><button type="button" class="button-reset2"><img src="' + pathImg + 'carrousel3/carrousel3-next.png" alt="' + l10n['next'] + '"></button></li>' +
                    '</ul>');
            }

            carousel.cycle({
                log:             false,
                fx:              'carousel',
                allowWrap:       false,
                carouselVisible: nbItemConfig,
                carouselFluid:   true,
                swipe:           true,
                timeout:         0,
                prev:            '.prevCaroussel3 button',
                next:            '.nextCaroussel3 button',
                slides:          '.item',
                autoHeight:      'calc'
            });

            $('.item', carousel).css('opacity', '1');
        }
    }
}

function carrousel3State(view) {
    var reinitTimer;

    var destroyCarrousel = function () {
        $('.carrousel3 .listItems').cycle('destroy');
    }

    function reinit_cycle(nbItem) {
        setTimeout(function () {
            destroyCarrousel();
            setTimeout(function () {
                carrousel3(nbItem);
            }, 200);
        }, 200);
    }

    if (view === 'mediaDesktop') {
        clearTimeout(reinitTimer);
        reinitTimer = setTimeout(reinit_cycle('5'), 200);
    } else if (view === 'mediaSmartphone') {
        clearTimeout(reinitTimer);
        reinitTimer = setTimeout(reinit_cycle('1'), 200);
    }  else if (view === 'mediaTablet') {
        clearTimeout(reinitTimer);
        reinitTimer = setTimeout(reinit_cycle('3'), 200);
    }
}


