// ouvre la fancybox auto sur la home
function fancyboxAutoHome() {
    // var popinDuration defined in the source code of the page
    if ($('.popin-home').length > 0 && typeof popinDuration !== "undefined") {
        var linkToPopinAccueil = $('.popin-home a');
        var urlToPopinAccueil = linkToPopinAccueil.attr('href');
        var popinMinWidth = (typeof popinFullscreen !== "undefined" && popinFullscreen === 1) ? '100%' : '';
        var popinMinHeight = (typeof popinFullscreen !== "undefined" && popinFullscreen === 1) ? $(window).height() : '';
        var popinClass = (typeof popinFullscreen !== "undefined" && popinFullscreen === 1) ? 'popin-home--fullscreen' : 'popin-home';
        var popinCloseButtonClass = (typeof popinFullscreen !== "undefined" && popinFullscreen === 1) ? 'button-1 filters__reset fancybox-btn-close' : 'fancybox-close';
        var popinCloseButtonTitle = (typeof popinFullscreen !== "undefined" && popinFullscreen === 1) ? l10n['goToSite'] + ' ' + window.location.hostname : l10n['close'];

        // var isConnected defined in the source code of the page
        if (sessionStorage.getItem('nopopin') === null || (typeof isConnected !== "undefined" && isConnected !== 0)) {
            $.fancybox.open(
                [{
                    href: urlToPopinAccueil
                }],
                {
                    type:        'iframe',
                    // Rend inactif les liens sous la popin
                    beforeLoad:  function () {
                        $('*[tabindex=0]').attr('tabIndex', -1).attr('datatabindex', 0);
                        $('a, input, button, select').attr('tabIndex', -1);
                        $('.fancybox-overlay a, .fancybox-overlay button, .fancybox-overlayinput').attr('tabIndex', 0);
                    },
                    // lien actif + focus sur le lien activé
                    afterClose:  function () {
                        $('*[datatabindex=0]').attr('tabIndex', 0);
                        $('a, button, input, select').attr('tabIndex', 0);
                        sessionStorage.setItem('nopopin', '1');
                        //show dialog if needed (need jquery-ui dialog)
                        /*$("#dialog-confirm").dialog({
                         resizable: false,
                         height: 160,
                         modal: true,
                         buttons: {
                         "Oui": function () {
                         $(this).dialog("close");
                         $('body').focus();
                         },
                         "Non": function () {
                         $.cookie("nopopin", "1", {path: "/", expires: 1});
                         $(this).dialog("close");
                         $('body').focus();
                         }
                         }
                         });*/
                    },
                    wrapCSS:     popinClass,
                    tpl:         {
                        closeBtn: '<a class="' + popinCloseButtonClass + '" href="javascript:;"></a>',
                        next:     '',
                        prev:     ''
                    },
                    afterLoad:   function () {
                        this.title = '<p class="hidden">' + this.title + '</p>';
                    },
                    afterShow:   function () {
                        // On renvoie le focus dans la popin
                        $(".fancybox-inner").attr("tabindex", 1).focus();
                        if (popinDuration) {
                            setTimeout(function () {
                                $.fancybox.close();
                            }, popinDuration * 1000);
                        }
                    },
                    minWidth:    popinMinWidth,
                    minHeight:   popinMinHeight,
                    padding:     0,
                    // Supression des effets
                    openEffect:  'fade',
                    closeEffect: 'fade',
                    nextEffect:  'none',
                    prevEffect:  'none',
                    // Externalise dans le tpl le title
                    helpers:     {
                        title: {
                            type: 'inside'
                        }
                    }
                }
            );
        }
    }
}
