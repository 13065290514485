$(document).ready(function () {
    var mediaSmartphone = 'mediaSmartphone',
        mediaTablet = 'mediaTablet',
        mediaDesktop = 'mediaDesktop';

    var jRes = jRespond([
        {
            label: 'smartphone',
            enter: 0,
            exit:  767
        }, {
            label: 'tablet',
            enter: 768,
            exit:  1279
        }, {
            label: 'desktop',
            enter: 1280,
            exit:  10000
        }
    ]);

    jRes.addFunc([
        {
            breakpoint: 'smartphone',
            enter:      function () {
                myInitFuncSmartphone();

            },
            exit:       function () {
            }
        },
        {
            breakpoint: 'tablet',
            enter:      function () {
                myInitFuncTablet();

            },
            exit:       function () {
            }
        },
        {
            breakpoint: 'desktop',
            enter:      function () {
                myInitFuncDesktop();

            },
            exit:       function () {
            }
        }
    ]);

    function myInitFuncSmartphone() {
        menuStratis(mediaSmartphone); // 190_stratis_menu.js
        fancyboxGalerie(mediaSmartphone); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaSmartphone); // 240_ stratis_galerie_classique.js
        filtersState(mediaSmartphone); // 300_stratis_filters.js
        //carrousel1State(mediaSmartphone); // 330_stratis_carrousel1.js
        carrousel2State(mediaSmartphone); // 340_stratis_carrousel2.js
        carrousel3State(mediaSmartphone); // 340_stratis_carrousel3.js
        showReportsMobile(); //305_stratis-reports.js
        menuMain1State(mediaSmartphone);
        iamState(); // 385_menu_I-am.js
        searchState(mediaSmartphone); // 290_stratis_search.js
        servicesState(mediaSmartphone); //
        slideBarState(); //
    }

    function myInitFuncTablet() {
        menuStratis(mediaTablet); // 190_stratis_menu.js
        fancyboxGalerie(mediaTablet); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaTablet); // 240_ stratis_galerie_classique.js
        filtersState(mediaTablet); // 300_stratis_filters.js
        //carrousel1State(mediaTablet); // 330_stratis_carrousel1.js
        carrousel2State(mediaTablet); // 340_stratis_carrousel2.js
        carrousel3State(mediaTablet); // 340_stratis_carrousel3.js
        showReportsMobile(); //305_stratis-reports.js
        menuMain1State(mediaTablet);
        iamState(); // 385_menu_I-am.js
        searchState(mediaTablet); // 290_stratis_search.js
        servicesState(mediaTablet); //
        slideBarState(); //
    }

    function myInitFuncDesktop() {
        // menuStratis(mediaDesktop); // 190_stratis_menu.js
        fancyboxGalerie(mediaDesktop); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaDesktop); // 240_stratis_galerie_classique.js
        filtersState(mediaDesktop); // 300_stratis_filters.js
        //carrousel1State(mediaDesktop); // 330_stratis_carrousel1.js
        carrousel2State(mediaDesktop); // 340_stratis_carrousel2.js
        carrousel3State(mediaDesktop); // 340_stratis_carrousel3.js
        menuMain1State(mediaDesktop);
        iamState(); // 385_menu_I-am.js
        searchState(mediaDesktop); // 290_stratis_search.js
        servicesState(mediaDesktop); //
        slideBarState(); //
    }

    // Generic functions
    carrousel1Desktop();
    preloadImages(); // 170_stratis_preload_images.js
    objectFitImages(); // 175_lib_ofi.min.js
    tooltipAccess(); // 200_stratis_tooltip.js
    tooltipDfn(); // 200_stratis_tooltip.js
    geocode(); // 205_stratis_geocode.js
    linkExternalNewWindow(); // 210_stratis_external_window.js
    fancyboxClassic(); // 220_stratis_fancybox_classic.js
    fancyboxAutoHome();  // 235_stratis_fancybox_auto_home.js
    sharePage(); // 250_stratis_share_page.js
    eventDdm(); // 260_stratis_event-ddm.js
    contentDdm(); // 270_stratis_content-ddm.js
    manageVideo(); // 280_stratis_video.js
    enableDatePicker(); // 310_stratis_datetimepicker.js
    enableTimePicker(); // 310_stratis_datetimepicker.js
    formValidation(); // 320_stratis_form_validation.js
    infiniteScroll(); // 350_stratis_infinite_scroll.js
    showTotal(); // 350_stratis_infinite_scroll.js
    catmenu(); // 355_stratis_catmenu.js
    cookiesManagement(); // 360_stratis_cookies_management.js
    faqDdm(); // 370_stratis_faq.js
    addContentInTitle(); // 380_stratis_add_in_title.js
    generatePie(); // 390_stratis_piechart.js
    svgxuse(); // 410_svgxuse.js
    filterDdm(); // 420_stratis_filter.js


});

accessTooltipState(); // 400_stratis_access-tooltip.js


