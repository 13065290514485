// I am

var iam = document.getElementById("iam");

function iamState() {
        $('.iam-box__wrapper').dropDownMenu({
            disabled:    false,
            classBtn:    'button-1 button-1--big',
            independent: false
        });
}

var iam1Clone = "iam-clone";

var cloneIam = function () {
    var iamClone = document.getElementById(iam1Clone);

    var iamCloned = iam.cloneNode(true);
    iamCloned.id = iam1Clone;

    var parentNode = document.getElementsByClassName("menu-main-1__sublevel");
    insertAfter(parentNode[0], iamCloned);

    iam.style.display = "none";
    iamClone = document.getElementById("iam-clone");
    iamClone.style.display = "block";

}
