function linkExternalNewWindow() {
    $('body a').not('.new-window-off').each(function () {
        if ($(this).find('.external').length === 0) {
            var $this = $(this),
                isExternal = $this.filter(function () {
                    return this.hostname && this.hostname !== location.hostname;
                }).length,
                isBlank = $this.filter(function() {
                    return $(this).attr('target') === '_blank';
                }).length,
                isNoopener = $this.filter(function() {
                    return $(this).prop('rel').indexOf('noopener') === 0;
                }).length,
                hasTtitle = $this.filter(function() {
                    return $(this).attr('title');
                }).length,
                hasAriaLabel = $this.filter(function() {
                    return $(this).attr('aria-label');
                }).length;

            var addAriaLabel = function(obj) {
                // If title, we get this and add linkNewWindow
                if (hasTtitle) {
                    var title = $this.attr('title');

                    $this.attr('aria-label', title + ' (' + l10n.linkNewWindow + ')');
                    $this.removeAttr('title');
                } else if (hasAriaLabel) {
                    var ariaLabel = $this.attr('aria-label');

                    $this.attr('aria-label', ariaLabel + ' (' + l10n.linkNewWindow + ')');
                } else {
                    var textArray = [];
                    var text;

                    [].forEach.call(obj[0].childNodes, function(item){
                        if (item.nodeName === 'IMG') {
                            textArray.push(item.alt);
                        } else if (item.nodeName === '#text') {
                            textArray.push(item.textContent.trim());
                        } else if (item.nodeName === 'svg') {
                            textArray.push(item.getAttribute('aria-label'));
                        } else {
                            textArray.push(item.innerText.trim());
                        }
                        text = textArray.join(' ');
                    });

                    obj.attr('aria-label', text + ' (' + l10n.linkNewWindow + ')');
                }
            };

            var addRelNoopener = function(obj) {
                if (obj.attr('rel')) {
                    var relValue = obj.attr('rel');
                    obj.attr('rel', relValue + ' noopener')
                } else {
                    obj.attr('rel', 'noopener');
                }
            };

            // target _blank
            if (isBlank === 1) {
                addAriaLabel($this);
                // Check rel noopener
                if (isNoopener === 0) {
                    addRelNoopener($this);
                }
            }
        }
    });
}
