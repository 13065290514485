// Caroussel2
var carouselWrap = $('.carrousel2'),
    carousel = $('.listItems', carouselWrap),
    reinitTimer;

function carrousel2(itemVisible) {
    if (carouselWrap.length > 0) {
        var nbItem = $('.item', carousel).length,
            nbItemConfig;

        if (itemVisible) {
            nbItemConfig = itemVisible;
        } else {
            nbItemConfig = '7';
        }

        if (nbItem > nbItemConfig) {
            if ($('.prevNextCaroussel2').length == 0) {
                carousel.parent().append('<ul class="prevNextCaroussel2">' +
                    '<li class="prevCaroussel2"><button type="button" class="button-reset"><img src="' + pathImg + 'carrousel2/carrousel2-prev.png" alt="' + l10n['prev'] + '"></button></li>' +
                    '<li class="nextCaroussel2"><button type="button" class="button-reset"><img src="' + pathImg + 'carrousel2/carrousel2-next.png" alt="' + l10n['next'] + '"></button></li>' +
                    '</ul>');
            }

            carousel.cycle({
                log:             false,
                fx:              'carousel',
                allowWrap:       false,
                carouselVisible: nbItemConfig,
                carouselFluid:   true,
                swipe:           true,
                timeout:         0,
                prev:            '.prevCaroussel2 button',
                next:            '.nextCaroussel2 button',
                slides:          '.item',
                autoHeight:      'calc'
            });

            $('.item', carousel).css('opacity', '1');
        }
    }
}

var destroyCarrousel = function () {
    $('.carrousel2 .listItems').cycle('destroy');
}

function reinit_cycle(nbItem) {
    setTimeout(function () {
        destroyCarrousel();
        setTimeout(function () {
            carrousel2(nbItem);
        }, 200);
    }, 200);
}

function carrousel2State(view) {
    if (view === 'mediaDesktop') {
        clearTimeout(reinitTimer);
        reinitTimer = setTimeout(reinit_cycle('7'), 200);
    } else if (view === 'mediaSmartphone') {
        clearTimeout(reinitTimer);
        reinitTimer = setTimeout(reinit_cycle('1'), 200);
    }  else if (view === 'mediaTablet') {
        clearTimeout(reinitTimer);
        reinitTimer = setTimeout(reinit_cycle('4'), 200);
    }
}
