// Dropdown Menu by Steven MOURET
/*
 * HTML structure
 * class is important
 <div class="ddm">
 <p class="ddm__title">Your title : </p>
 <div class="ddm__sub-level">
 <ul class="ddm__listitems">
 <li class="ddm__item"><a href="#">item1</a></li>
 <li class="ddm__item"><a href="#">item2</a></li>
 <li class="ddm__item"><a href="#" class="ddm__close">item3</a></li>
 </ul>
 </div>
 </div>

 options:
 disabled: disable drop down menu
 classBtn: For add classes
 independent: If true, does not close when I click somewhere else.

 JS Call :
 $('.ddm').dropDownMenu({
 disabled: false,
 classBtn: 'button-1 button-1--active',
 independent: false
 });
 * */

$.fn.dropDownMenu = function (options) {

    var defaults = {
        disabled: false,
        classBtn: '',
        independent: false,
        titleAfter: false,
        textOpen: null,
        textClose: null
    };

    var titleDropDown;

    var settings = $.extend( {}, defaults, options );

    $(this).each(function () {

        var obj = $(this),
            menuDropDown = $('.ddm__sub-level:first', obj),
            lastItem = $('.ddm__item:last, .ddm__close', menuDropDown),
            state = false;

        if (settings.titleAfter === true) {
            titleDropDown = obj.children(".ddm__title");
        } else {
            titleDropDown = $('.ddm__title:first', obj);
        }

        if (settings.textOpen != null) {
            titleDropDown.text(settings.textOpen);
        }

        function uniqId() {
            return Math.round(new Date().getTime() + (Math.random() * 100));
        }

        var id = 'ddm__' + uniqId();

        if (!titleDropDown.children().hasClass('ddm__button')) {
            if (settings.classBtn) {
                titleDropDown.wrapInner('<button type="button" class="ddm__button ' + settings.classBtn + '" aria-controls="' + id + '"></button>');
            } else {
                titleDropDown.wrapInner('<button type="button" class="ddm__button" aria-controls="' + id + '"></button>');
            }
        }

        var buttonDropDown = titleDropDown.find('.ddm__button');

        if (settings.disabled) {
            menuDropDown.find('a, input, select, button').attr('tabindex', 0);
        } else {
            menuDropDown.find('a, input, select, button').attr('tabindex', -1);
        }

        function openSubLevel() {
            if (!settings.disabled) {
                menuDropDown.find('a, input, select, button').attr('tabindex', 0);
            }
            if (settings.textClose != null) {
                buttonDropDown.text(settings.textClose);
            }
            obj.addClass('ddm--active');
            menuDropDown.attr('aria-hidden', 'false');
            buttonDropDown.attr('aria-expanded', 'true');
        }

        function closeSubLevel() {
            if (!settings.disabled) {
                menuDropDown.find('a, input, select, button').attr('tabindex', -1);
            }
            if (settings.textOpen != null) {
                buttonDropDown.text(settings.textOpen);
            }
            obj.removeClass('ddm--active');
            menuDropDown.attr('aria-hidden', 'true');
            buttonDropDown.attr('aria-expanded', 'false');
        }

        menuDropDown.attr('id', id);

        if (settings.disabled === true) {
            // Reset
            obj.removeClass('ddm--active');
            obj.addClass('ddm--off');
            obj.removeClass('ddm--on');
            menuDropDown.removeAttr('aria-hidden');
            if (titleDropDown.children('.ddm__button').length == 1) {
                titleDropDown.children('.ddm__button').contents().unwrap();
            }
        } else {
            obj.removeClass('ddm--off');
            obj.addClass('ddm--on');
            buttonDropDown.attr('aria-haspopup', 'true');
            buttonDropDown.attr('aria-expanded', 'false');
            menuDropDown.attr('aria-hidden', 'true');

            lastItem.on('click focusout', function () {
                closeSubLevel();
                state = false;
            });

            buttonDropDown.on('click', function () {
                if (state === false) {
                    openSubLevel();
                    state = true;
                } else {
                    closeSubLevel();
                    state = false;
                }
            });
        }

        // hide when clic somewhere else
        if (settings.independent === false) {
            $(document).not(obj).on('click', function (e) {
                if (!$(e.target).closest(obj).length) {
                    closeSubLevel();
                    state = false;
                }
            });
        }
    });
};
