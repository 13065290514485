// Filters
function filtersState(state) {
    if (state == 'mediaDesktop' || state == 'mediaTablet') {
        $('.filters .ddm.form--3').dropDownMenu({
            disabled:    true,
            classBtn:    '',
            independent: true
        });
    } else if (state == 'mediaSmartphone') {
        $('.filters .ddm.form--3').dropDownMenu({
            disabled:    false,
            classBtn:    'button-2 button-2--no-text-in-mobile',
            independent: true
        });
    }
}

// Drop down menu for periods and places
var filterDdm = function() {
    $('.ddm__facet').dropDownMenu({
        disabled: false,
        classBtn: '',
        independent: false
    });

    var inputChecked = function() {
        $('.ddm__facet input[type=radio]').change(function() {
            //console.log("change facet");
            if(this.checked) {
                var text = $(this).next().text();
                var button = $(this).closest('.ddm__facet').find('.ddm__button');
                button.html(text);
            }
        });
    };
    inputChecked();

    $('.ddm__more-filters').dropDownMenu({
        disabled: false,
        classBtn: '',
        independent: true,
        titleAfter: true,
        textOpen: 'Plus de critères de recherche',
        textClose: 'Moins de critères de recherche'
    });
};


// Check fields
// Check categories
var checkCategories = function() {
    var checkboxesWrapper = $('.filters .filters__category');

    checkboxesWrapper.on("change", "input:checkbox", function(){
        console.log('submit category');
        return true;
    });
};
// Check periods
var checkPeriods = function() {
    var radiosWrapper = $('.filters .radio-periods');

    radiosWrapper.on("change", "input:radio", function(){
        console.log('submit Periods');
        return true;
    });
};
// Check places
var checkPlaces = function() {
    var radiosWrapper = $('.filters .radio-places');

    radiosWrapper.on("change", "input:radio", function(){
        console.log('submit Places');
        return true;
    });
};
// Check dates
var checkDates = function() {
    var button = $('.filters .button-2--date');
    var inputStart = $('.filters #start-date');
    var inputEnd = $('.filters #end-date');

    button.click(function() {
        var valInputStart = inputStart.val();
        var valInputEnd = inputEnd.val();

        if (valInputEnd != "" && valInputStart === "") {
            alert('Vous devez choisir une date de début');
            return false;
        } else if (valInputEnd === "" && valInputStart != "") {
            alert('Vous devez choisir une date de fin');
            return false;
        } else if (valInputEnd === "" && valInputStart === "") {
            alert('Vous devez choisir une date de début et de fin');
            return false;
        } else {
            console.log('submit dates');
            return true;
        }
    });
};
// Check past events
var checkPastEvents = function() {
    var checkboxesInput = $('#past-events');

    checkboxesInput.on("change", function(){
        console.log('submit past events');
        return true;
    });
};
// Check album types
var checkAlbumTypes = function() {
    var checkboxesWrapper = $('.filters .filters__album-types');

    checkboxesWrapper.on("change", "input:checkbox", function(){
        console.log('submit album types');
        return true;
    });
};
// Check past publications
var checkPastPublications = function() {
    var checkboxesInput = $('#past-publications');

    checkboxesInput.on("change", function(){
        console.log('submit past publications');
        return true;
    });
};
// Validate filter fields
var validateFilters = function() {
    if (checkDates() || checkCategories() || checkPeriods() || checkPlaces() || checkPastEvents() || checkAlbumTypes() || checkPastPublications()) {
        // We can run ajax.
        return true;
    } else {
        return false;
    }
};

validateFilters();

// Reset filters
var resetFilters = function() {
    var resetButton = $('.filters .filters__reset-2');

    resetButton.click(function() {
        // Reset categories
        var categoriesCheckboxes = $('.filters .filters__category input[type=checkbox]');
        categoriesCheckboxes.prop('checked', false);

        // Reset periods
        var periodsRadios = $('.filters .radio-periods input[type=radio]');
        periodsRadios.prop('checked', false);
        var ddmPeriodsButton = $('.filters__periods .ddm__button');
        ddmPeriodsButton.text('- Sélectionner une période -');

        // Reset keywords
        var keywords = $('#keyword');
        keywords.val('');

        // Reset periods
        var placesRadios = $('.filters .radio-places input[type=radio]');
        placesRadios.prop('checked', false);
        var ddmPlacesButton = $('.filters__places .ddm__button');
        ddmPlacesButton.text('- Sélectionner un lieu -');

        // Reset dates
        var inputStart = $('.filters #start-date');
        var inputEnd = $('.filters #end-date');
        inputStart.val('');
        inputEnd.val('');

        // Reset past events
        var checkboxesInput = $('#past-events');
        checkboxesInput.prop('checked', false);

        // Reset past publications
        var checkboxesInput = $('#past-publications');
        checkboxesInput.prop('checked', false);

        // Reset album types
        var albumTypesCheckboxes = $('.filters .filters__album-types input[type=checkbox]');
        albumTypesCheckboxes.prop('checked', false);

        // Here we reload ajax.
    });
};

resetFilters();
