// services

var service = document.getElementById("services");

function servicesState(state) {
    if (state == 'mediaDesktop') {
        $('.services-box__wrapper').dropDownMenu({
            disabled: false,
            classBtn: '',
            independent: false
        });
        $('.services-box .ddm__button').click(function () {
            $('.services-box .ddm.services-box__wrapper').css("z-index", "9999");
        });
    } else if ((state == 'mediaSmartphone') || (state == "mediaTablet")) {
        $('.services-box__wrapper').dropDownMenu({
            disabled: true,
            classBtn: '',
            independent: false
        });
        $('.services-box__wrapper').dropDownMenu({
            disabled: false,
            classBtn: '',
            independent: false
        });
    }
}


var serviceMenu1Clone = "services__menu-clone";

var cloneServiceSlideMenu = function (media) {
    var slideServiceMenuClone = document.getElementById(serviceMenu1Clone);
    if (service && slideServiceMenuClone) {
        var slideServiceCloned = service.cloneNode(true);
        slideServiceCloned.id = serviceMenu1Clone;

        if(media == "mediaTablet") {
            var parentNode = document.getElementsByClassName("iam-box");
            insertAfter(parentNode[0], slideServiceCloned);
        }
        if(media == "mediaSmartphone") {
            var parentNode2 = document.getElementById('search-clone');
            insertAfter(parentNode2, slideServiceCloned);
        }

        service.style.display = "none";
        slideServiceMenuClone = document.getElementById("services__menu-clone");
        slideServiceMenuClone.style.display = "inline-block";
    }
}
