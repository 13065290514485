$(document).ready(function () {

    var mapCategoryTrigger = $('.map-categories__btn');

    mapCategoryTrigger.on('click', function() {
        var filterId = $(this).attr('data-filter-id');
        selectFilter(filterId);
    });

    $('.js-close-column').on('click', function () {
        $(this).parents('.setPos').addClass('is-hidden');
        $('.map-categories__item').removeClass('is-active');
    });

    $('.carto .map__popup').jScrollPane({
        autoReinitialise: true,
        showArrows: true,
        animateScroll: true
    });

    $('.localiser .map__popup').jScrollPane({
        autoReinitialise: true,
        showArrows: true,
        animateScroll: true
    });

    $('.carto .map-column--filter').jScrollPane({
        autoReinitialise: true,
        showArrows: true,
        animateScroll: true
    });

    var api = $('.map__search .container').jScrollPane({
        showArrows: true,
        autoReinitialise: true,
        animateScroll: true
    }).data('jsp');

    var defaultText = $('.js-trigger-options').text();

    $('.js-trigger-options').on('click', function() {

        var hiddenFields = $('.filters__plus-fields');
        var text = {
            closed: defaultText,
            opened: "Moins de critères de recherche"
        };

        if (hiddenFields.hasClass('is-visible')) {
            hiddenFields.removeClass('is-visible');
            $(this).text(text.closed)
            $(this).removeClass('is-expanded');
        } else {
            hiddenFields.addClass('is-visible');
            $(this).text(text.opened)
            $(this).addClass('is-expanded');
        }

    });

    //$('.carto form input, .carto form select').on('change', function () {
    //   if ($(this).val() !== '' && $(this).val().length > 0) {
    //       $(this).parents('form').trigger('submit');
    //   }
    //});

    // $('.map__search__arrow.scroll-up').click(function () {
    //     api.scrollByY(-50);
    //     return false;
    // });
    //
    // $(".filters__reset").on('click', function () {
    //     $('.filters__hide').trigger('click');
    // });
    //
    // $('.map__search__arrow.scroll-down').click(function () {
    //     api.scrollByY(50);
    //     return false;
    // });
    //
    // $('.filters__hide').click(function () {
    //     $('.side-nav-close.white-nav-close').trigger('click');
    //     toggleMapSearchResults();
    // });

    // toggleMapSearchResults();
    // showArrows();

});

function selectFilter(id) {
    var filterForms = $('.filters__form');
    var mapCategories = $('.map-categories__item');
    var filterColumn = $('.map-column--filter');

    mapCategories.removeClass('is-active');
    filterForms.removeClass('is-active');

    filterForms.each(function() {

        var filterId = $(this).attr('data-filter');

        if (id === filterId ) {
            $(this).addClass('is-active');
            mapCategories.find('[data-filter-id="'+id+'"]').parent().addClass('is-active');
            filterColumn.parent().removeClass('is-hidden');
        }
    });

}


// function showArrows() {
//     var panelHeight = $('.jspPane').height();
//     var containerHeight = $('.carto .map__search .container').height();
//
//     if (panelHeight > containerHeight) {
//         $('.map__search__arrow').addClass('map__search__arrow--visible');
//         $('.carto .map__search ul').removeClass('no-margin');
//
//     } else {
//         $('.map__search__arrow').removeClass('map__search__arrow--visible');
//         $('.carto .map__search ul').addClass('no-margin');
//     }
// }
//
// function toggleMapSearchResults() {
//     var map = $('.map-content');
//     var btn = $('.filters__hide');
//
//     if (map.hasClass('map__search--active')) {
//         btn.removeClass('filters__hide--hidden');
//     } else {
//         btn.addClass('filters__hide--hidden');
//     }
// }

$(window).resize(function () {
    // $('.map__search .container').jScrollPane({
    //     reinitialize: true
    // });

    // showArrows();
});
