// Reportages : show 2 items random on mobile
function random(min, max) {
    return (Math.floor((max - min) * Math.random()) + min);
}

function showReportsMobile() {
    var r = random(1, 6);
    var r2 = random(1, 6);
    if (r == r2) {
        r2 = random(1, 6);
    }
    $(".reports article").each(function () {
        if ($(this).hasClass('report__item--' + r)) {
            $(this).css('display', 'inline-block');
            $(this).addClass("visible");
        } else if ($(this).hasClass('report__item--' + r2)) {
            $(this).css('display', 'inline-block');
        } else {
            $(this).hide();
        }
    });
}

