// plugin galerie Classique
(function ($) {
    $.fn.classicalGallery = function (options) {
        var defaults = {
            numberThumbs: 4,
            reset: false
        };
        var options = $.extend(defaults, options);

        this.each(function () {
            var obj = $(this),
                slider = $('.slider-galerie', obj),
                carousel = $('.carousel-galerie__thumb', obj),
                lengthItem = carousel.find('.carousel-galerie__item').length;

            // No reset
            if (options.reset == false) {

                carousel.wrap('<div class="carousel-galerie"></div>');

                $('.carousel-galerie', obj).append('<ul class="carousel-galerie__pager">' +
                    '<li class="carousel-galerie__prev"><button type="button">' + l10n['prev'] + '</button></li>' +
                    '<li class="carousel-galerie__next"><button type="button">' + l10n['next'] + '</button></li>' +
                    '</ul>');

                var next = $('.carousel-galerie__pager .carousel-galerie__next', obj),
                    prev = $('.carousel-galerie__pager .carousel-galerie__prev', obj);

                if (lengthItem > options.numberThumbs) {
                    carousel.on('cycle-update-view', function (e, opts, slideOpts, currSlide) {
                        var titlePager = $(this).find('.carousel-galerie__item').eq(opts.currSlide + 1).find('button img').attr('alt');
                        // Active title button
                        opts.slides.find('button').removeAttr('title');
                        opts.slides.eq(opts.currSlide).find('button').attr('title', titlePager + ' (Image active)');
                    });

                    slider.cycle({
                        prev: prev,
                        next: next,
                        //autoHeight: '500:333',
                        timeout: 0,
                        manualFx: "fade",
                        slides: ".slider-galerie__item",
                        log: false
                    });

                    carousel.cycle({
                        timeout: 0,
                        slides: '.carousel-galerie__item',
                        fx: 'carousel',
                        allowWrap: false,
                        carouselVisible: options.numberThumbs,
                        carouselFluid: true,
                        log: false
                    });

                    var slideshows = $('.cycle-slideshow', obj).on('cycle-next cycle-prev', function (e, opts) {
                        slideshows.not(this).cycle('goto', opts.currSlide);
                    });

                    $('.cycle-slide', carousel).click(function () {
                        var index = carousel.data('cycle.API').getSlideIndex(this);
                        slideshows.cycle('goto', index);
                    });
                } else {
                    // Manage pager
                    carousel.addClass('no-carousel');

                    obj.on('cycle-update-view', function (e, opts, slideOpts) {
                        var altCurrThumùb = slideOpts.pager.children().eq(opts.currSlide).find('img').attr('alt');
                        // Active title button
                        slideOpts.pager.children().find('button').removeAttr('title');
                        slideOpts.pager.children().eq(opts.currSlide).find('button').attr('title', altCurrThumùb + ' (Image active)');
                    });

                    slider.cycle({
                        prev: prev,
                        next: next,
                        timeout: 0,
                        manualFx: "fade",
                        slides: ".slider-galerie__item",
                        pager: carousel,
                        log: false,
                        pagerTemplate: '' // For use specific template
                    });
                }

            } else {
                // unwrap carousel-galerie
                var carouselGalerieWrapContent = $('.carousel-galerie', obj).contents();
                var nbItems = slider.find('.slider-galerie__item').length;
                if ($('.classical-gallery__nb-items', obj).length == 0) {
                    obj.append('<p class="classical-gallery__nb-items">+ ' + nbItems + '</p>');
                }


                $('.carousel-galerie', obj).replaceWith(carouselGalerieWrapContent);

                obj.find('.carousel-galerie__pager').remove();
                slider.cycle('destroy');
                carousel.cycle('destroy');
            }

        });
    };
})(jQuery);


function initGalerieClassique(view) {
    if (view === 'mediaDesktop') {
        // reset
        $('.classical-gallery').classicalGallery({
            numberThumbs: 4,
            reset: true
        });
        $('.classical-gallery').classicalGallery({
            numberThumbs: 4,
            reset: false
        });
    } else if ((view === 'mediaSmartphone') || (view === 'mediaTablet')) {
        $('.classical-gallery').classicalGallery({
            numberThumbs: 4,
            reset: true
        });
    }
}

$(".link-all-videos").click(function() {
    $('a.fancyboxGalerie-video').eq(0).trigger('click');
    return false;
});
