// slide bar festifolies

var slidebar = document.getElementById("slide-bar");

function slideBarState() {
    $('.slide-bar__wrapper').dropDownMenu({
        disabled: false,
        classBtn: '',
        independent: false
    });
    $('.slide-bar__wrapper .ddm__button').click(function () {
        $('.services-box .ddm.services-box__wrapper').css("z-index", "200");
    });
}


var slideBar1Clone = "slide-bar-clone";

var cloneSlideBarMenu = function (media) {
    var slideBarClone = document.getElementById(slideBar1Clone);

    if (slideBarClone && slidebar) {
        var slideBarCloned = slidebar.cloneNode(true);
        slideBarCloned.id = slideBar1Clone;

        var parentNode2 = document.getElementById('services__menu-clone');
        insertAfter(parentNode2, slideBarCloned);

        slidebar.style.display = "none";
        slideBarClone = document.getElementById("slide-bar-clone");
        slideBarClone.style.display = "inline-block";
    }
}
