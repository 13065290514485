// Search
var search = document.getElementById("search");

function searchState(state) {

    if($('.search-box__wrapper').length) {
        if (state == 'mediaDesktop') {
            $('.search-box__wrapper').dropDownMenu({
                disabled: false,
                classBtn: 'button-1 button-1--no-icon button-1--svg',
                independent: false
            });
            search.style.display = "block";
        }
        else if (state == 'mediaTablet') {
            $('.search-box__wrapper').dropDownMenu({
                disabled: true,
                classBtn: 'button-1 button-1--no-icon button-1--svg',
                independent: false
            });
            search.style.display = "block";

        } else if (state == 'mediaSmartphone') {
            $('.search-box__wrapper').dropDownMenu({
                disabled: false,
                classBtn: 'button-1 button-1--no-icon button-1--svg',
                independent: false
            });
            $('.search-box__wrapper').dropDownMenu({
                disabled: true,
                classBtn: 'button-1 button-1--no-icon button-1--svg',
                independent: false
            });
        }
    }

}


// clone search box

var search1Clone = "search-clone";

var cloneSearch = function () {
    var searchClone = document.getElementById(search1Clone);

    var searchCloned = search.cloneNode(true);
    searchCloned.id = search1Clone;
    if ($('body').hasClass('home')) {
        var parentNode = document.getElementsByClassName("iam-box");
    } else {
        var parentNode = document.getElementsByClassName("menu-main-1__sublevel");
    }

    searchCloned.querySelector('.search-box__field-wrapper label').setAttribute("for", "search-box__input--mobile");
    searchCloned.querySelector('.search-box__field-wrapper input').setAttribute("id", "search-box__input--mobile");
    insertAfter(parentNode[0], searchCloned);

    search.style.display = "none";
    searchClone = document.getElementById("search-clone");
    searchClone.style.display = "block";

}
